// App.js
import React, { lazy, Suspense } from "react";
import 'video-react/dist/video-react.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from './Wrappers/Layout';

const Portfolio = lazy(() => import("./Components/Portfolio/Portfolio"));
const Contact = lazy(() => import("./Components/Contact/Contact"));

function App() {
  return (
    <Router>
      <Layout>
        <Suspense fallback={<div><p></p></div>}>
          <Switch>
            <Route path="/" exact component={Portfolio} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </Suspense>
      </Layout>
    </Router>
  );
}

export default App;